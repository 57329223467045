import novelaTheme from '@narative/gatsby-theme-novela/src/gatsby-plugin-theme-ui';

export default {
  ...novelaTheme,
  initialColorMode: `light`,
  colors: {
    ...novelaTheme.colors,
    primary: '#00303f',
    secondary: '#e4b82f',
    accent: '#b28c17',
    grey: '#000000',
    background: '#fdf9f1',
    card: '#d5d1c7',
    name: '#000000',
    inputBackground: '#fdf9f1',
    error: '#EE565B',
    errorBackground: '#fdf9f1',
    gradient: 'linear-gradient(180deg, #fdf9f1 0%, #ffffff 100%)',
    modes: {
      dark: {
        primary: '#e4b82f',
        secondary: '#b28c17',
        accent: '#e4b82f',
        grey: '#ffffff',
        buttonHover: '#ffffff',
        background: '#00303f',
        articleText: '#fdf9ef',
        progress: '#e4b82f',
        card: '#003547',
        inputBackground: '#fdf9f1',
        error: '#EE565B',
        errorBackground: '#fdf9f1',
        gradient: 'linear-gradient(180deg, #00303f 0%, #00090c 100%)',
      },
    },
  },
};
